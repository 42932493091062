import React, { useEffect, useState } from 'react';
import axios from 'axios';
import FishGame from './FishGame'; // Переносим игру в отдельный компонент
import Button from 'react-bootstrap/Button'; // Импортируем компонент Button
import Table from 'react-bootstrap/Table'; // Импортируем компонент Table
import 'bootstrap-icons/font/bootstrap-icons.css'; // Импортируем стили для иконок
import './App.css'; // Импортируем ваши стили

const serverUrl = 'https://fishgame.fun/api'; // Обновите URL вашего сервера

function getParameterByNameFromFragment(name) {
  const fragment = window.location.hash.substring(1);
  const params = new URLSearchParams(fragment);
  return params.get(name);
}

function App() {
  const [points, setPoints] = useState(0);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [telegramId, setTelegramId] = useState('');
  const [topPlayers, setTopPlayers] = useState([]);
  const [showGame, setShowGame] = useState(false);

  useEffect(() => {
    const tgWebAppData = getParameterByNameFromFragment('tgWebAppData');
    if (tgWebAppData) {
      try {
        const params = new URLSearchParams(tgWebAppData);
        const userData = params.get('user');
        if (userData) {
          const tgData = JSON.parse(decodeURIComponent(userData));
          setFirstName(tgData.first_name);
          setLastName(tgData.last_name);
          setTelegramId(tgData.id);

          // Регистрация игрока на сервере
          axios.post(`${serverUrl}/register`, {
            telegramId: tgData.id,
            firstName: tgData.first_name || '',
            lastName: tgData.last_name || ''
          })
          .then(response => {
            // Получаем очки игрока после регистрации
            setPoints(response.data.points);
          })
          .catch(error => {
            console.error('Ошибка при регистрации игрока:', error.response ? error.response.data : error.message);
            setPoints(0);
          });

          // Получаем топ-3 игроков
          fetchTopPlayers();
        }
      } catch (error) {
        console.error("Error parsing tgWebAppData: ", error);
      }
    }
  }, []);

  const fetchTopPlayers = () => {
    axios.get(`${serverUrl}/top-players`)
      .then(response => {
        console.log('Топ-игроки:', response.data);
        setTopPlayers(response.data);
      })
      .catch(error => {
        console.error('Ошибка при получении топ-3 игроков:', error.response ? error.response.data : error.message);
      });
  };

  const handleShowGame = () => {
    setShowGame(true);
  };

  const handleBack = () => {
    setShowGame(false);
    fetchTopPlayers(); // Обновляем топ-игроков при возврате
  };

  return (
    <div className="App">
      {!showGame ? (
        <div className="main-screen">
          <div className="game-description">
            <h1>Добро пожаловать в Сообщество Сибирский Лов!</h1>
            <p>
              В этой игре ваша цель - набрать максимальное количество очков. 
              Каждый ваш клик по рыбе увеличивает ваше количество очков. 
              В конце месяца топ-3 игрока с наибольшим количеством очков получат ценные призы от нашего интернет-магазина. 
              Так что постарайтесь набрать как можно больше очков и войдите в топ!
            </p>
          </div>
          <div className="user-info">
            <i className="bi bi-person-vcard"></i> {firstName} {lastName}
          </div>
          <div className="top-players">
            <h2>Турнирная таблица</h2>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Имя</th>
                  <th>Фамилия</th>
                  <th>Очки</th>
                </tr>
              </thead>
              <tbody>
                {topPlayers.map((player, index) => (
                  <tr key={player.telegramId}>
                    <td>{index + 1}</td>
                    <td>{player.firstName}</td>
                    <td>{player.lastName}</td>
                    <td>{player.points}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <Button onClick={handleShowGame} variant="outline-primary" className="start-game-button">
            <i className="bi bi-play-circle"></i> Приступить к игре
          </Button>
        </div>
      ) : (
        <FishGame points={points} setPoints={setPoints} telegramId={telegramId} handleBack={handleBack} />
      )}
    </div>
  );
}

export default App;
