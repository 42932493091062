import React from 'react';
import Fish from './Fish';
import axios from 'axios';
import Button from 'react-bootstrap/Button'; // Импортируем компонент Button
import Badge from 'react-bootstrap/Badge'; // Импортируем компонент Badge
import 'bootstrap-icons/font/bootstrap-icons.css'; // Импортируем стили для иконок

const serverUrl = 'https://fishgame.fun/api'; // Обновите URL вашего сервера

function FishGame({ points, setPoints, telegramId, handleBack }) {
  const handleFishClick = () => {
    const newPoints = points + 1;
    setPoints(newPoints);

    // Обновляем очки на сервере
    axios.post(`${serverUrl}/update-points`, {
      telegramId,
      points: newPoints
    })
    .then(response => {
      console.log('Очки успешно обновлены');
    })
    .catch(error => {
      console.error('Ошибка при обновлении очков:', error.response ? error.response.data : error.message);
    });
  };

  return (
    <div className="fish-game">
      <header className="App-header">
        <div className="points-display">
          <Badge bg="secondary" style={{ fontSize: '18px', padding: '10px' }}>
            <i className="bi bi-trophy" style={{ marginRight: '5px' }}></i>
            Очки: {points}
          </Badge>
        </div>
        <Fish onClick={handleFishClick} />
        <Button onClick={handleBack} variant="outline-secondary" className="back-button">
          <i className="bi bi-arrow-left"></i> Главное меню
        </Button>
      </header>
    </div>
  );
}

export default FishGame;
