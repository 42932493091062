import React, { useEffect, useState } from 'react';
import fishImage from './fish.svg'; // Поместите изображение рыбы в папку src
import './Fish.css';

function Fish({ onClick }) {
  const [position, setPosition] = useState({ top: '50%', left: '50%' });
  const [direction, setDirection] = useState('right');

  useEffect(() => {
    const moveFish = () => {
      const randomTop = Math.floor(Math.random() * 80) + 10; // Диапазон от 10% до 90%
      const randomLeft = Math.floor(Math.random() * 80) + 10; // Диапазон от 10% до 90%
      setPosition({ top: `${randomTop}%`, left: `${randomLeft}%` });
      
      // Меняем направление в зависимости от позиции
      setDirection(randomLeft > 50 ? 'right' : 'left');
    };

    const interval = setInterval(moveFish, 2000); // Перемещение каждые 2 секунды

    return () => clearInterval(interval);
  }, []);

  return (
    
    <img
      src={fishImage}
      className={`Fish ${direction}`}
      alt="fish"
      style={{ top: position.top, left: position.left }}
      onClick={onClick}
    />
  );
}

export default Fish;
